

  
  .caroselrow{
margin-right: 0px;
  }

  .sec p{
    line-height: 40px;
   }
   .sec ul{
    padding: 10px;
   }
/** flip card***/

.flip-card {
    background-color: transparent;
    width: 400px;
    height: 100%;
    perspective: 1000px;
    border-radius: 30px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    border-radius: 30px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #ffffff;
    color: black;
  }
  .flip-card-front .princi{
    width: 400px;
    border-radius: 12px;
   
  }
  
  .flip-card-back {
    background-color: #79031d;
    color: white;
    transform: rotateY(180deg);
  }

  /*** end ****/

  /*** New Face View **/

  .faceview a{
    text-decoration: none;
  }
  /*** end ****/
 


/*** Spinner Start ***/

#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .8s ease-out, visibility 0s linear .5s;
    z-index: 99999;
 }

 #spinner.show {
     transition: opacity .8s ease-out, visibility 0s linear .0s;
     visibility: visible;
     opacity: 1;
 }

/*** Spinner End ***/


/*** Button Start ***/
.btn {
    font-weight: 600;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-md-square {
    width: 46px;
    height: 46px;
}

.btn-lg-square {
    width: 58px;
    height: 58px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.back-to-top {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

/*** Button End ***/


/*** Topbar Start ***/

.topbar .top-info {
    letter-spacing: 1px;
    justify-content: space-between;
}

.topbar .top-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar .top-link a {
    margin-right: 10px;
}

#note {
    width: 0px;
    overflow: hidden;
}

#note small {
    position: relative;
    display: inline-block;
    animation: mymove 30s infinite;
    animation-timing-function: all;
}

@keyframes mymove {
    from {left: -100%;}
    to {left: 100%;}
}

/*** Topbar End ***/


/*** Navbar Start ***/
.navbar .navbar-nav {
    padding: 15px 0;
}

.navbar .navbar-nav .nav-link {
    padding: 10px;
    color: var(--bs-white);
    font-size: 18px;
    outline: none;
    
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: #fcd752 !important;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "font awesome 5 free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        opacity: 0;
       
    }
    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
      position: fixed !important; 
      
    }
    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        opacity: 1;
    }
}

/*** Navbar End ***/


/*** Services Start ***/

.services .services-item {
    box-shadow: 0 0 60px rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 10px 0;
    position: relative;
}


.services-content::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
    background: #00FFFF;
   
    transition: .5s;
}

.services-content::after {
    top: 0;
    bottom: auto;
    border-radius: 10px 10px 10px 10px;
}

.services-item:hover .services-content::after {
    height: 100%;
    opacity: 1;
    transition: .5s;
}

.services-item:hover .services-content-icon {
    position: relative;
    z-index: 2;
}

.services-item .services-content-icon i,
.services-item .services-content-icon p {
    transition: .5s;
}

.services-item:hover .services-content-icon i {
    color: var(--bs-danger) !important;
}

.services-item:hover .services-content-icon p {
    color: black;
}

/*** Services End ***/


/*** Project Start ***/


.project-img {
    position: relative;
    padding: 15px;
}

.project-img::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    left: 0;
    background: #03AED2;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
    transition: .5s;
}

.project-img::after {
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #92ffd2;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
    transition: .5s;
}

.project-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.project-content a {
    display: inline-block;
    padding: 20px 25px;
    background: #f21137;
    border-radius: 10px;
    text-decoration: none;
}

.project a{
    text-decoration: none;
}

.project-item:hover .project-content {
    opacity: 1;
    transition: .5s;
}

.project-item:hover .project-img::before,
.project-item:hover .project-img::after {
    opacity: 0;
}

/*** Project End ***/


/*** Blog Start ***/
.blog-item .blog-btn {
    z-index: 2;
}

.blog-btn .blog-btn-icon {
    height: 50px;
    position: relative;
    overflow: hidden;
    color: white;
}

.blog-btn-icon .blog-icon-2 {
    display: flex;
    position: absolute;
    top: 6px;
    left: -140px;
    
}

.blog-btn-icon:hover .blog-icon-2 {
    transition: 1s;
    left: 5px;
    top: 6px;
    padding-bottom: 5px;
}
.blog-icon-1 {
    position: relative;
    top: -4px;
}
.blog-btn-icon:hover {
    color: white;
}

.blog-bbtn {
    color: white;
}



/*** Blog End ***/


/*** Team Start ***/

.team-item {
    border-top: 30px solid #79031d !important;
    background: rgba(239, 239, 241, 0.8);
}

.team-content::before {
    height: 200px;
    display: block;
    content: "";
    position: relative;
    top: -101px;
    background: #79031d;
    clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
    padding: 60px;
    opacity: 1;
}

.team-img-icon {
    position: relative;
    margin-top: -200px;
    padding: 30px;
    padding-bottom: 0;
}

.team-img {
    border: 15px solid var(--bs-white);
}

.team-img img {
    border: 10px solid  #fcd752;
    transition: .5s;
}

.team-item:hover h4 {
    color: #79031d;
    transition: .5s;
}

.team-item:hover .team-img img {
    transform: scale(1.05);
    border: 10px solid  #fcd752;
}

.team-carousel .owl-stage {
    position: relative;
    width: 100%;
    height: 100%;
}

.team-carousel .owl-nav {
    position: absolute;
    top: -100px;
    right: 50px;
    display: flex;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    margin-left: 15px;
    background: var(--bs-secondary);
    color: var(--bs-white);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
    background: #79031d;
    color: var(--bs-white);
}

@media (max-width: 992px) {
    .team-carousel {
        margin-top: 3rem;
    }

    .team-carousel .owl-nav {
        top: -85px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        margin-left: -15px;
    }
}

/*** Team End ***/


/*** Testimonial Start ***/

.testimonial-item {
    background: #e3f0eb;

}

.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: #c1dad0;
    border-radius: 15px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 30px;
    background: var(--bs-primary);
}

.testimonial-carousel .owl-item.center {
    position: relative;
    z-index: 1;
}

.testimonial-carousel .owl-item .testimonial-item {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: #FFFFFF !important;
    box-shadow: 0 0 30px #DDDDDD;
}

/*** Testimonial End ***/


/*** Contact Start ***/
.contact-detail::before {
    position: absolute;
    content: "";
    height: 50%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    border-radius: 10px;
    z-index: -1;
}

.contact-detail a{
    text-decoration: none;
}

.contact-map {
    background: #26d48c;
}



/*** Contact End ***/


/*** Footer Start ***/

.footer .short-link a,
.footer .help-link a,
.footer .contact-link a {
    transition: .5s;
    text-decoration: none;
}

.footer .short-link a:hover,
.footer .help-link a:hover,
.footer .contact-link a:hover {
    letter-spacing: 1px;
}

.footer .hightech-link a:hover {
    background: var(--bs-secondary);
    border: 0;
}

/*** Footer End ***/

@media only screen and (min-width:991px) {
    .flip-card{
        width: 100%;
        margin-left: auto;
        margin-right: auto;

    }
    
}

@media only screen and (max-width:990px ){
    .flip-card {
        width: 100%;
        height: 220px;
        margin-left: auto;
        margin-right: auto;
      }
      .flip-card-front .princi{
        width: 320px;
        border-radius: 12px;
      }
      .sec p{
        font-size: 16px;
      }
      
}


@media only screen and (max-width: 767px) {
    
    .flip-card {
        width: 250px;
        height: 200px !important;
        margin-left: auto;
        margin-right: auto;
      }
      .flip-card-front{
        width: 250px;
      }
      .flip-card-front .princi{
        width: 250px;
        border-radius: 12px;
       
      }
      .flip-card-back p{
        font-size: 12px !important;
      }
      .vjbanner h4{
        font-size: 16px;
      }
   .sec p{
    font-size: 14px;
    line-height: 30px;
   }
   .sec ul{
    padding: 0px !important;
   }

   .project p{
    font-size: 14px;
}
    }