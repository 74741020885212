/* Marquee styles */
.marquee {
  --gap: .5rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 1;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  margin-bottom: 0;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__content {
    animation-play-state: paused !important;
  }
}

/* Enable animation */
.enable-animation .marquee__content {
  animation: scroll 10s linear infinite;
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
  animation-play-state: paused;
}






/* Other page demo styles */
.marquee__content > * {
  flex: 0 0 auto;
  color: white;
  background: dodgerblue;
  margin: 0px;
  padding: .25rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
}

/*.marquee {
  border: 2px dashed lightgray;
}*/
