.custom-loader {
    --d:108px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fcd752;
    box-shadow: 
      calc(1*var(--d))      calc(0*var(--d))     0 0,
      calc(0.707*var(--d))  calc(0.707*var(--d)) 0 2px,
      calc(0*var(--d))      calc(1*var(--d))     0 4px,
      calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 6px,
      calc(-1*var(--d))     calc(0*var(--d))     0 8px,
      calc(-0.707*var(--d)) calc(-0.707*var(--d))0 10px,
      calc(0*var(--d))      calc(-1*var(--d))    0 12px;
    animation: s7 2s infinite steps(8);
  }

  .preloaderidntica {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: none repeat scroll 0;
  }


  .image-position {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index value as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as desired */
  }
  
  @keyframes s7 {
    100% {transform: rotate(1turn)}
  }