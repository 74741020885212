/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");*/
::-webkit-scrollbar { 
  display: none;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 10;
  box-sizing: border-box;
}

.timelineCSS  {
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);
  min-height: 100vh;
 
  display: grid;
  align-content: center;
  gap: 1rem;
  padding: 1rem;
  padding-right: 2rem;
  /*font-family: "Young Serif", serif;*/
  color: var(--color);
  background: var(--bgColor);
}

h1 {
  text-align: center;
  
 /* font-family: "Young Serif";*/
}

.timelineCSS ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  /*display: grid;*/
  grid-template-columns: 1fr var(--line-w) 1fr;
  /*grid-template-columns: var(--line-w) 1fr;*/
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(90rem, 90%);
  margin-inline: auto;
}

/* line */
.timelineCSS ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.timelineCSS ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.timelineCSS ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.timelineCSS ul li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);
  /*font-family: "Poppins", sans-serif;*/
  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timelineCSS ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.timelineCSS ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.timelineCSS ul li .title,
ul li .descr {
  background: var(--bgColor);
  position: relative;
 
}
.timelineCSS ul li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.timelineCSS ul li .descr {
  padding-block-end: 1.5rem;
 /* font-weight: 300;*/
  height: 300px;
  overflow-y: auto;
  text-align: justify;
}

/* shadows */
.timelineCSS ul li .title::before,
ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.timelineCSS ul li .title::before {
  bottom: calc(100% + 0.125rem);
}

.timelineCSS ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 80rem) {
  .timelineCSS ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .timelineCSS ul::before {
    grid-column: 2;
  }
  .timelineCSS ul li:nth-child(odd) {
    grid-column: 1;
  }
  .timelineCSS ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .timelineCSS ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .timelineCSS  ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .timelineCSS  ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .timelineCSS ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.timelineCSS .credits {
  margin-top: 1rem;
  text-align: right;
}
.timelineCSS .credits a {
  color: var(--color);
}

@media only screen and (max-width: 1200px) {
.timelineCSS{
  padding: 8px;
  padding-top: 30px;
}
  .timelineCSS h1{
    font-size: 28px;
  }
  .timelineCSS ul li .date {
    font-size: 12px;
  }
  .timelineCSS ul li .title {
    font-size: 14px;
    padding-block-start: 8px;
    padding-block-end: 8px;
  }
  .timelineCSS ul li .descr {
    font-size: 11px !important;
    padding-block-end: 8px !important;
  }
  .timelineCSS ul{
    padding: 10px;
  }
  .timelineCSS ul li{
    --inlineP: 6px;
  }
}