:root {
  --Brown: #79031d;
  --DGreen: #41B3A2;
  --LBlue: #1e90ff;
  --white: #ffffff;
  --violet:#7A1CAC;
  --blue:#03AED2;
  --Nblue:#00FFFF;
  --Dblue:#3603a2;
  --BPink:#C21292;
  --DPink:#970747;
  --BGreen: #92ffd2;
  --Lgreen: #A5DD9B;
  --GBlue:#15c4b2;
}

.App {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: center;
  font-size: large;
}

.sjinfo h5{
  color: var(--Brown)
}
.principal-title{
  font-family: "Holtwood One SC", serif;
  font-size: 24vm;
  padding-bottom: 8px;
  font-weight: 500;
  font-style: normal;
  overflow: hidden;
  margin-right: -20%;
  z-index: 1000; 
}
.princi-text{
  font-family: "Baskervville", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 40px;
}

.principal-img-bg
{
  background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 36%, rgba(0,156,237,1) 100%);
z-index: 299;
}

.gradient-bg-dark-main
{
  background: rgb(0,74,255);
  background: linear-gradient(90deg, rgba(0,74,255,1) 0%, rgb(21, 179, 207) 50%, rgba(65,75,247,1) 100%);
}

.sjinfo p{
  /*font-family: "Young Serif", serif;*/
  font-size: 20px;
  text-align: justify;
  color: var(--Dblue);
  line-height: 40px;
}

.vission-mission p{
  font-size: 20px;
  text-align: justify;
  color: black;
  line-height: 40px;
}
.project p {
  font-size: 22px;
  color: black;
  text-align: justify;
}
/*.sec p{
  
  font-family: "Young Serif", serif;
}*/

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical; /* Ensure the box's children (text) are arranged vertically */
  overflow: hidden; /* Hide any content that overflows the 3 lines */
  text-overflow: ellipsis; /* Optional: Add ellipsis (...) at the end of the truncated text */
}
.truncate-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical; /* Ensure the box's children (text) are arranged vertically */
  overflow: hidden; /* Hide any content that overflows the 3 lines */
  text-overflow: ellipsis; /* Optional: Add ellipsis (...) at the end of the truncated text */
}
a{
  text-decoration: none;
}

Link {
  text-decoration: none;
}
.gal-sec-view{
  height: 650px;
  overflow: auto;
}
.navbar-main p{
  font-size: 30px;
}
.navbar-main{
display: block;

}
.navbar-mobile{
  display: none !important;
}
.navbar-main img{
  width: 6rem;
  height: 6rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body::-webkit-scrollbar{
  display: none ;
}
.bg-dark-main{
  
  background-color:var(--blue);
}
.bg-dark-sub{
  background-color: var(--Gblue);
}
.text-dark-main{
  /*
  color: #79031d; */
  color:var(--Dblue);
}
.banner-dark-bg{
  background-color: var(--GBlue);
  border-radius: 20px;
  color:var(--white);
}
.gal-bg-view{
  background-color: var(--blue);
}
.topbar, a {
  color:var(--Dblue);
  text-decoration: none;
}
.light-text-combo{
  color: #f21137;
}
.light-bg{
  background-color: #f21137;
}
 /* color: #fcd752; */
.Ylight-text-combo{
 color: var(--Dblue);
}
.Ylight-bg{
  background-color:var(--BGreen);
}
.Dark-bg{
  background-color: var(--Dblue);
}
.topbar{
  display: block;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9999;
}
.navbar-nav a{
  /*font-family: "Roboto Condensed";*/
  text-decoration: none;
}

.navbar-nav .nav-item:hover{

  text-decoration:none
}
.navbar-nav a:hover{
  color: var(--Dblue) !important;
}

.darkThemeButton {
  background-color:var(--blue);;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.darkThemeButton:hover {
  background-color: #555;
  transform: scale(1.05);
}

/*
.navbar-nav a{
  color: var(--Nblue) !important;
  font-family: "Roboto Condensed";
  
}

.navbar-nav .nav-item:hover{
  background-color: var(--BPink);
  border-radius: 15%;
  text-decoration: underline;
}
.navbar-nav .dropdown-menu{
  background-color: var(--Dblue);
}
.navbar-nav .dropdown-menu a:hover{
  color: var(--Dblue) !important;
}

*/

.course-title {
  display: flex;
  
  height: 80px;
  justify-content: center;


}
.course-title h5{
  background-color: var(--DGreen);
  border-radius: 25%;
  width:400px;
  text-align: center;
  padding: 15px;
  color: var(--white);
}
.zoom {
  padding: 20px;
  transition: transform .2s; /* Animation */
  width: 80px;
  height: 300px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.imgrotation{
  width: 300px;
  animation: rotation 6s 1 linear;
}

.imgrotation:hover{
  width: 300px;
  animation: rotation 6s 1 linear;
}



/*----- Gallery -project icon hover ------*/

.proj {
  display: block;
  width: 100%;
  margin-bottom: 0;
  background-position: top center !important;
  z-index: 0;
  position: relative; 
  
}


  .proj:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
   /* background: #bdb5b5; Enable this for shade overlay on hover */ 
    opacity: 0;
    z-index: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .proj .text span {
    color: rgba(255, 255, 255, 0.9); }
  .proj h2 {
    color: #fff;
    line-height: 1.2;
    font-size: 18px; }
    .proj h2 a {
      color: #fff; }
  .proj .desc {
    z-index: 1; }
  .proj .icon {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 70px;
    height: 70px;
    content: '';
    z-index: 1;
    background: #fdbe34;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    margin-top: -70px;
    opacity: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .proj .icon span {
      color: #fff; }
  .proj:hover:after {
    opacity: .7; }
  .proj:hover .icon {
    opacity: 1; }






@-webkit-keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-30px);} 
  60% {-webkit-transform: translateY(-15px);} 
} 

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
}

.animated { 
  -webkit-animation-duration: 2s;
  animation-duration: 2s; 
  -webkit-animation-fill-mode: both; 
  animation-fill-mode: both; 
}


.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
  animation-iteration-count: infinite;
  animation-delay: 5s;
}

.fade-in-image { 
  animation: fadeIn 3s; 
  animation-iteration-count: infinite;
}

.fade-out-image { 
  animation: fadeOut 3s; 
  animation-iteration-count: infinite;
}

.fade-in-text {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  100% { opacity: 1; }
  50% { opacity: 0.5; }
}





@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.checklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checklist li {
  padding: 10px;
  position: relative;
}

.checklist i {
  padding: 20px;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1380px) {
  .navbar-main p{
    font-size: 25px;
  }
  .navbar-main h3{
    padding-top: 3%;
  font-size: 18px;
   
  }
  .navbar-main img{
   width: 5rem;
   height: 5rem;
  }
  .navbar .navbar-nav .nav-link{
    font-size: 16px !important;
  }
}
@media (max-width: 1200px) {
  .navbar-main p{
    font-size: 22px;
  }
  .topbar small{
    font-size: 14px;
   }
  .navbar-main h3{
  font-size: 15px;
   
  }
  .navbar-main img{
   width: 4rem;
   height: 4rem;
  }
  .navbar .navbar-nav .nav-link{
    font-size: 15px !important;
  }
}
@media (max-width: 980px) {
  .navbar-main p{
    font-size: 20px;
  }
   .navbar-main h3{
   font-size: 22px;
    
   }
   .navbar-main img{
    width: 3rem;
    height: 3rem;
   }
   .topbar small{
    font-size: 12px;
   }
}
@media (max-width: 992px) {
  .topbar{
    display: none;
  }
  .navbar h3{
    font-size: 13px;
  }
  .navbar-main{
    display: none !important;
    }
    .navbar-mobile{
      display: block !important;
    }
   .navbar-brand h3{
   font-size: 16px;
    
   }
}
@media (max-width: 991px) {
  
  .navbar-mobile h3{
  font-size: 12px;
  padding-top: 1.8%!important;
   
  }
 
}
@media (max-width: 600px) {
  
  .navbar-mobile h3{
  font-size: 12px;
  padding-top: 1.8%!important;
 
   
  }
  .principal-title{
    font-family: "Holtwood One SC", serif;
    font-size: 14px;
    text-align: center;
    margin: 0 !important;
  }
  .princi-text{
    font-size: 12px;
    line-height: 20px;
  }
}